<template>
  <div :id="chartId">
    <a-empty v-show="empty" style="padding-top:80px;color:#bfbfbf;" />
  </div>
</template>

<script>
import { Chart, registerTheme } from '@antv/g2'
import { getAlertEventTrend } from '@/api/alert-event'
import { severityColor } from '@/utils'

registerTheme('newTheme', { maxColumnWidth: 60 })

export default {
  name: 'AlertEventTrendChart',
  props: {
    dateRange: { type: Array },
    projectId: { type: String },
    trendBy: { type: String, required: true }
  },
  data () {
    return {
      chart: null,
      empty: false
    }
  },
  computed: {
    chartId () {
      return `${this.trendBy}_trend`
    }
  },
  mounted () {
    this.chart = new Chart({ container: this.chartId, autoFit: true })
    this.chart.scale('value', { min: 0, nice: true })
    this.chart.tooltip({ shared: true, showMarkers: false })
    switch (this.trendBy) {
      case 'severity':
        this.chart.scale('name', {
          formatter: value => value ? this.$t(`severity.${value}`) : value
        })
        this.chart
          .interval()
          .position('date*value')
          .color('name', v => severityColor(v))
          .adjust('stack')
        break
    }
    this.chart.interaction('element-highlight-by-color')
    this.chart.theme('newTheme')
    this.fetch()
  },
  methods: {
    fetch () {
      const params = { trend_by: this.trendBy, recovered: false }
      if (this.dateRange.length !== 0) {
        params.date_from = this.dateRange[0].format('YYYY-MM-DD')
        params.date_to = this.dateRange[1].format('YYYY-MM-DD')
      }
      if (this.projectId) params.project_id = this.projectId
      getAlertEventTrend(params).then(res => {
        if (res.data.data.length) {
          this.chart.changeVisible(true)
          this.empty = false
        } else {
          this.chart.changeVisible(false)
          this.empty = true
        }
        this.chart.data(res.data.data)
        this.chart.forceFit()
        this.chart.render()
      })
    }
  },
  watch: {
    dateRange () {
      this.fetch()
    },
    projectId () {
      this.fetch()
    }
  }
}
</script>
