<template>
  <div>
    <a-form-model :model="projectForm">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="5">
          <a-form-model-item prop="project_id">
            <project-select v-model="projectForm.project_id"></project-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <!-- 告警趋势 -->
    <a-row>
      <a-col style="margin-bottom: 16px;">
        <a-card :body-style="{ padding: '0 24px 24px 24px' }" :bordered="false" :head-style="{ border: 'none' }"
          :hoverable="true" :title="trendItem.title">
          <a-form-model :model="dateForm" slot="extra">
            <a-row :gutter="16">
              <a-col>
                <a-form-model-item prop="dateRange" class="date-row">
                  <a-range-picker v-model="dateForm.dateRange" :allow-clear="false" :disabled-date="disabledDate"
                    format="YYYY-MM-DD"></a-range-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <alert-event-trend :date-range="dateForm.dateRange" :project-id="projectForm.project_id" :trend-by="trendItem.key"
            style="height: 300px;"></alert-event-trend>
        </a-card>
      </a-col>
    </a-row>
    <!-- 告警时间轴 -->
    <a-row>
      <a-col style="margin-bottom: 16px;">
        <a-card :body-style="{ padding: '0 24px 24px 24px' }" :bordered="false" :head-style="{ border: 'none' }"
          :hoverable="true" title="告警时间轴">
          <alert-event-timeline :project-id="projectForm.project_id"></alert-event-timeline>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import ProjectSelect from '@/components/select/ProjectSelect'
import AlertEventTrend from './modules/AlertEventTrend'
import AlertEventTimeline from './modules/AlertEventTimeline.vue'

export default {
  name: 'AlertEventList',
  components: { ProjectSelect, AlertEventTrend, AlertEventTimeline },
  data () {
    return {
      dateForm: {
        dateRange: [moment().subtract(15, 'day'), moment()]
      },
      projectForm: {},
      trendItem: { key: 'severity', title: '未恢复告警趋势' }
    }
  },
  methods: {
    disabledDate (date) {
      return date && date > moment().endOf('day')
    }
  }
}
</script>

<style lang="less">
.date-row{
  margin-bottom: 0;
}
</style>
