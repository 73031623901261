<template>
  <div>
    <a-timeline v-if="data.length" size="small">
      <a-timeline-item v-for="item in data" :key="item.id">
        <a-descriptions class="relatimeDescription" style="backgroundColor:rgb(248, 248, 248);padding:10px 15px 0"
          :title="item.created_at" :column="4">
          <a-descriptions-item>
            <a @click="$router.push({ name: 'AlertEventDetail', params: { id: item.id } })">{{ item.name }}</a>
          </a-descriptions-item>
          <a-descriptions-item v-if="item.source">
            <a-tag color="blue">{{ $t(`source_type.${item.source_type}`) }}</a-tag>
            <router-link :to="{ name: routerName(item.source_type), params: { id: item.source.id } }">
              <span>{{ item.source.name }}</span>
            </router-link>
          </a-descriptions-item>
          <a-descriptions-item v-else>-</a-descriptions-item>
          <a-descriptions-item v-if="item.projects.length">
            {{ item.projects | ProjectNames }}
          </a-descriptions-item>
          <a-descriptions-item v-else>-</a-descriptions-item>
          <a-descriptions-item label="持续">{{ duration(item.triggered_at, item.recovered_at) }}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
    </a-timeline>
     <a-empty v-else style="padding-top:80px;padding-bottom:50px;color:#bfbfbf;" />
    <div v-if="hasMore" class="loadMore">
      <a style="padding-left:20px" @click="loadMore">加载更多...</a>
    </div>
  </div>
</template>

<script>
import { getAlertEventList } from '@/api/alert-event'
import moment from 'moment'
import { humanTime, getSourceRouterName } from '@/utils'

export default {
  name: 'AlertEventTimeline',
  props: {
    projectId: { type: String }
  },
  data () {
    return {
      data: [],
      params: { recovered: false, page: 1, page_size: 10 },
      total: 0
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    hasMore () {
      return this.total > this.params.page * this.params.page_size
    }
  },
  watch: {
    projectId () {
      this.params.page = 1
      this.data = []
      this.fetch()
    }
  },
  methods: {
    fetch () {
      if (this.projectId) this.params.project_id = this.projectId
      else delete this.params.project_id
      getAlertEventList(this.params).then(res => {
        const data = res.data
        this.data.push(...data.data)
        this.total = data.total
      })
    },
    duration (triggeredAt, recoveredAt) {
      let second = 0
      if (recoveredAt) second = (moment(recoveredAt) - moment(triggeredAt)) / 1000
      else second = (moment() - moment(triggeredAt)) / 1000
      return humanTime(second)
    },
    routerName (sourceType) {
      return getSourceRouterName(sourceType)
    },
    loadMore () {
      if (this.hasMore) {
        this.params.page++
        this.fetch()
      }
    }
  },
  filters: {
    ProjectNames: (list) => {
      let str = ''
      for (let i = 0; i < list.length; i++) {
        str += list[i].name
        if (i < list.length - 1) str += ','
      }
      return str
    }
  }
}
</script>

<style lang="less">
.relatimeDescription {
  .ant-descriptions-title {
    margin-bottom: 10px;
  }
}

.timeline-empty {
  .ant-empty-description {
    color: rgb(191, 191, 191) !important;
  }
}
</style>
